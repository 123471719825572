<template>
  <Popper placement="right" arrow class="products-popper">
    <div class="products-renderer">
      <button class="btn btn-w"><i class="fal fa-clipboard-list"></i></button>
    </div>
    <template #content>
      <table class="product-table">
        <tr>
          <th>Наименование</th>
          <th>Кол-во</th>
          <th>Цена</th>
          <th>Сумма</th>
        </tr>
        <tr
          v-for="(product, index) of Object.values(params.value)"
          :key="index"
        >
          <td>{{ product.name }}</td>
          <td>{{ product.count }}</td>
          <td>{{ product.price }} ₽</td>
          <td>{{ product.price * product.count }} ₽</td>
        </tr>
      </table>
      <div v-if="params.data.entities && params.data.entities.length">
        <div class="additional-title">Дополнительно:</div>
        <table class="product-table">
          <tr>
            <th>Наименование</th>
            <th>Кол-во</th>
          </tr>
          <tr v-for="(product, index) of params.data.entities" :key="index">
            <td>{{ product.name }}</td>
            <td>{{ product.count }}</td>
          </tr>
        </table>
      </div>
    </template>
  </Popper>
</template>

<style lang="scss" scoped>
.products-renderer {
  cursor: pointer;
}

.products-popper {
  font-size: 14.5px;
  line-height: 20px;

  .btn {
    padding: 6px 8px;
  }

  .product-table tr {
    border-bottom: 2px solid #ebebeb;
    border-collapse: collapse;
  }

  .product-table th,
  .product-table td {
    padding: 3px;
    border-left: 1px solid var(--cream-color);
    border-collapse: collapse;
    white-space: nowrap;
  }

  .product-table th:last-child,
  .product-table td:last-child {
    border-right: 1px solid var(--cream-color);
  }

  .product-table th {
    border-bottom: 1px solid var(--cream-color);
  }

  .product-table td {
    padding-right: 5px;
  }

  .product-table td:first-child {
    white-space: pre-wrap;
  }

  .additional-title {
    margin: 48px 2px 8px 2px;
    font-weight: 600;
    border-bottom: 1px solid var(--cream-color);
  }
}
</style>
<style lang="scss">
.products-popper .popper {
  //   padding: 10px 0 10px 0 !important;
  background-color: var(--main-color);
  color: var(--text-color);
  -webkit-box-shadow: 0px 5px 10px -3px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px -3px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px -3px rgba(34, 60, 80, 0.2);
  width: 480px;

  &:hover {
    background-color: white;
    color: var(--text-color);
  }

  #arrow::before,
  #arrow:hover::before {
    background-color: white !important;
    -webkit-box-shadow: 0px 5px 10px -3px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 5px 10px -3px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 5px 10px -3px rgba(34, 60, 80, 0.2);
  }

  @media (max-width: 425px) {
    width: 200px;
  }
}
</style>
